/* .NavBar {
  background-color: #fff;
  float: left; 
  min-height: 7vh;
  padding: 14px 16px;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(5px + 1.8vmin);
  font-family: Rubik;
  color: rgba(29, 29, 29, 0.7);
} */

.NavBar {
  background-color: #fff;
  overflow: hidden;
  min-height: 15vh;
  font-family: Rubik;
  flex-wrap: nowrap;
  align-content: normal;
  justify-content: center;
  align-items: center;
  position: relative;
}

.NavBar a {
  float: left;
  color: rgba(29, 29, 29, 0.7);
  text-align: center;
  padding: 14px 16px;
  padding-top: 5vh;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-decoration: none;
}

.NavBar-Main {
  float: none;
  position: absolute;
  top: 23%;
  padding-top: 5vh;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: bold;
}

.NavBar-Right {
  float: right;
  color: rgba(29, 29, 29, 0.7);
  text-align: center;
  padding: 14px 16px;
  padding-top: 5vh;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-decoration: none;
}

.topCenter {
  text-align: center;
  justify-content: center;
}

.logo {
  /* display: inline-block; */
  /* padding-top: 1vh; */
  /* padding-left: 28vh; */
  text-align: center;
  justify-content: center;
}

/* .NavBar-left {
  float: left;
} */
