/* .NavBar {
  background-color: #fff;
  float: left; 
  min-height: 7vh;
  padding: 14px 16px;
  font-style: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(5px + 1.8vmin);
  font-family: Rubik;
  color: rgba(29, 29, 29, 0.7);
} */

.InfoBar {
  background-color: #fff;
  overflow: hidden;
  min-height: 15vh;
  font-family: Rubik;
  flex-wrap: nowrap;
  align-content: normal;
  justify-content: center;
  align-items: center;
  position: relative;
}

